<template>
  <div class="allOrders">
    <el-form inline style="margin-top: 20px">
      <el-form-item label="客户名称" prop="name">
        <el-input v-model="order_search.name" placeholder="客户名" />
      </el-form-item>
      <el-form-item label="订单编号" prop="order_no">
        <el-input v-model="order_search.order_no" placeholder="订单编号" />
      </el-form-item>
      <el-form-item label="物流编号" prop="logist_no">
        <el-input v-model="order_search.delivery_no" placeholder="物流编号" />
      </el-form-item>
      <el-form-item label="订单状态" prop="status">
        <el-select v-model="order_search.status" placeholder="订单状态">
          <el-option v-for="(item, index) in status_" :key="index" :label="item.name" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="下单日期" prop="logist_no">
        <el-date-picker :unlink-panels="true" v-model="seachTime" type="daterange" range-separator="到"
          start-placeholder="Start date" end-placeholder="End date"/>
      </el-form-item>
      <el-form-item label="邀请码" prop="label">
        <el-input v-model="order_search.label" placeholder="邀请码" />
      </el-form-item>
      <el-form-item v-if="downDept" label="工作站" prop="status">
        <el-select filterable v-model="order_search.department_id" clearable placeholder="选择下属工作站">
          <el-option v-for="item in downDept" :label="item.department" :key="item.id" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button :icon="Search" @click="order_submit_search">查询</el-button>
      </el-form-item>
      <el-form-item label="金额" prop="label">
        <el-input v-model="sumTotal" disabled />
      </el-form-item>
      <el-alert type="info" :closable="false" title="搜索结果列表" style="margin-bottom: 5px;" />
    </el-form>
    <el-table :data="order_list" size="small">
      <el-table-column prop="order_no" label="订单编号" align="center" width="160px"></el-table-column>
      <el-table-column prop="name" label="客户" align="center" width="80px"></el-table-column>
      <el-table-column prop="created_at" label="下单时间" align="center" width="160px"></el-table-column>
      <el-table-column prop="total" label="金额" align="center" width="100px"></el-table-column>
      <el-table-column prop="logist_name" label="物流名称" align="center" width="120px"></el-table-column>
      <el-table-column prop="delivery_no" label="物流编号" align="center" width="160px"></el-table-column>
      <el-table-column prop="status" label="订单状态" align="center">
        <template #default="{ row }">
          <span>{{ status[row.status] }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template #default="{ row }">
          <el-button size="small" v-show="row.status > 0" @click="logistics(row)">查看物流</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="display: flex; justify-content: center;padding: 20px 0;">
      <!-- <el-pagination background :page-size="order_pagination.pageSize" :current-page="order_pagination.page" :page-sizes="[100, 200, 300, 400]"
        :total="order_pagination.total" @current-change="pageChange" layout="sizes, prev, pager, next, jumper, total"
        small="small" /> -->
      <el-pagination v-model:current-page="order_pagination.page" v-model:page-size="order_pagination.pageSize" :page-sizes="[10, 20, 50, 100, 200, 500]" small="small" :background="true" layout="total, sizes, prev, pager, next, jumper"
        :total="order_pagination.total" @size-change="handleSizeChange" @current-change="pageChange" />
    </div>
  </div>
</template>
<script setup>
import axios from '@/libs/axios'
import { reactive, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { Search } from '@element-plus/icons-vue'
import { useStore } from 'vuex'

const store = useStore()
const user = store.state.user
const downDept = user.downDept
const sumTotal = ref(0)
const status = ['未发货', '已发货', '拒收未入库', '拒收已入库', '无(自提)', '取消', '签收', '问题件', '转寄', '清关']
const status_ = reactive([
  { name: '所有', value: -1 },
  { name: '未发货', value: 0 },
  { name: '已发货', value: 1 },
  { name: '拒收未入库', value: 2 },
  { name: '拒收已入库', value: 3 },
  { name: '无(自提)', value: 5 },
  { name: '签收', value: 6 },
  { name: '问题件', value: 7 },
  { name: '转寄', value: 8 },
  { name: '清关', value: 9 }
])
// -1：“所有”， "0":"未发货","1":"已发货","2":"拒收未入库","3":"拒收已入库","4":"取消","5":"无(自提)"，6-签收，7-问题件，8-转寄，9-清关
const order_list = ref([])

const order_pagination = reactive({
  page: 1,
  pageSize: 10,
  total: 0
})

const seachTime = ref()

const order_search = reactive({
  name: '',
  order_no: '',
  delivery_no: '',
  status: -1,
  start: null,
  end: null,
  label: '',
  department_id: null
})

const router = useRouter()

const handleSizeChange = (val) => {
  order_pagination.pageSize = val
  order_submit_search()
}

const logistics = order => {
  router.push({ name: 'Logistics', query: { delivery_no: order.delivery_no, logist_name: order.logist_name } })
}

const pageChange = page => {
  order_pagination.page = page
  order_submit_search()
}

const order_submit_search = async () => {
  if (seachTime.value) {
    console.log('seachTime :>> ', seachTime)
    order_search.start = timeFomat(seachTime.value[0]) + ' 00:00:00'
    order_search.end = timeFomat(seachTime.value[1]) + ' 23:59:59'
  }
  // console.log('time', seachTime.value)
  // console.log('submit')
  const response = await axios.post('/orders/getByAdmin', { ...order_search, ...order_pagination })
  order_list.value = response.data
  sumTotal.value = response.sumTotal ? response.sumTotal : 0
  order_pagination.total = response.total
}

const timeFomat = (data) => {
  const start = new Date(data)
  // 开始
  const yearStart = start.getFullYear()
  const monthStart = ('0' + (start.getMonth() + 1)).slice(-2)
  const dayStart = ('0' + start.getDate()).slice(-2)
  // const hours = ('0' + start.getHours()).slice(-2)
  // const minutes = ('0' + start.getMinutes()).slice(-2)
  // const seconds = ('0' + start.getSeconds()).slice(-2)
  return `${yearStart}-${monthStart}-${dayStart}`
}

onMounted(() => {
  order_submit_search()
})
</script>
<style lang="less" scoped></style>
